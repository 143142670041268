export const API_BASE: any = {
	SIGN_KEY: 'l]Ybbrj_ZnFz4~9VB7)Gq7[0HGCY]oq{1*I]Eji_e[(~qVRYB#axu+r6IDzeE0*EvX2&BCWt~QmXZc==',
	dev: {
		wap: 'https://wap.bgt.940114.xyz',
		api: 'https://api.bgt.940114.xyz/Public/boosoov2/',
	},
	prod: {
		wap: 'https://wap.bgt.bogetai.com',
		api: 'https://api.bgt.bogetai.com/Public/boosoov2/',
	},
};
